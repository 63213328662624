<template lang="html">
<el-dialog
  title="Banner详情"
  :visible.sync="dialogVisible"
  width="50%">
  <div>
  	<el-descriptions title="">
		    <el-descriptions-item label="Banner标题">{{info.name}}</el-descriptions-item>
		    <el-descriptions-item label="Banner ID">{{info.id}}</el-descriptions-item>
		    <el-descriptions-item label="创建时间">{{info.createTime}}</el-descriptions-item>
		    <el-descriptions-item label="生效时间">{{info.beginTime}}</el-descriptions-item>
		    <el-descriptions-item label="到期时间">{{info.endTime}}</el-descriptions-item>
		    <el-descriptions-item label="最后操作时间">{{info.updateTime}}</el-descriptions-item>
		    <el-descriptions-item label="固定跳转类型">{{fixedJumpTypeOptions[info.fixedJumpType]}}</el-descriptions-item>
		    <el-descriptions-item :label=" info.fixedJumpType == '2'?'标的ID':'跳转url'" v-if="info.fixedJumpType == '1' || info.fixedJumpType == '2'">{{info.jumpUrl}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{info.type == '1'?'启动页':'首页'}}</el-descriptions-item>
        <el-descriptions-item label="排序">{{info.sort}}</el-descriptions-item>
		    <el-descriptions-item label="状态">{{info.onlineFlag === 1?'有效':'无效'}}</el-descriptions-item>
        <el-descriptions-item label="操作用户名称">{{info.operateAccountName}}</el-descriptions-item>
		    <el-descriptions-item label="操作用户ID">{{info.operateId}}</el-descriptions-item>
       
		</el-descriptions>
    <el-descriptions title="">
       <el-descriptions-item label="名称描述">{{info.nameDesc}}</el-descriptions-item>  
    </el-descriptions>
    <el-descriptions>
      <el-descriptions-item label="图片">
          <a :href="info.imgUrl" target="_blank">
            <img :src="info.imgUrl" style="width:108px;height:108px;">
          </a>
        </el-descriptions-item>
    </el-descriptions>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
import {mapState} from 'vuex';
    export default {
    	data(){
    		return{
    			dialogVisible:false
    		}
    	},
      props:['info'],
    	components:{},
      computed:{
        ...mapState({
          fixedJumpTypeOptions: state=>state.dictionary.fixedJumpTypeOptions
        })
      },
    	methods:{
    		cancel(){
    			this.dialogVisible = false
    		},
    	}
    }
</script>

<style scoped lang="scss">
	
</style>