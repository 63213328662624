<template>
  <el-dialog :title="operType==='add'?'添加Banner':'编辑Banner'" :visible.sync="dialogVisible" width="600px">
  <el-form :model="form" :rules="rules" ref="addBannerForm" label-width="100px">
    <el-form-item label="Banner标题" prop="name">
      <el-input v-model="form.name" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="开始时间" prop="beginTime">
      <el-date-picker 
      value-format="yyyy-MM-dd HH:mm:ss" 
      type="datetime" 
      placeholder="选择日期" 
      v-model="form.beginTime" 
      style="width: 203px;"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="到期时间" prop="endTime">
      <el-date-picker 
      value-format="yyyy-MM-dd HH:mm:ss" 
      :picker-options="pickerOptions" 
      type="datetime" placeholder="选择日期" 
      v-model="form.endTime" 
      style="width: 203px;"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="类型" :rules="{type:'string',required:true,message:'请选择类型',trigger:'change'}">
      <el-select v-model="form.type" placeholder="请选择">
        <el-option label="启动页" value="1"></el-option>
        <el-option label="首页" value="2"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="固定跳转类型">
      <el-select v-model="form.fixedJumpType" placeholder="请选择">
        <el-option :label="item" :value="key" v-for="(item,key) in fixedJumpTypeOptions" :key="key"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="标的ID" v-if="form.fixedJumpType == '2'">
        <el-input v-model="form.jumpUrl" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="跳转url" v-if="form.fixedJumpType == '1'">
      <el-input v-model="form.jumpUrl" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="排序号">
      <el-input-number size="small" v-model="form.sort" :min="0"></el-input-number>
    </el-form-item>
    <el-form-item label="图片" prop="imgUrl" required>
      <img-upload :img.sync="form.imgUrl" @updateImg="updateImg"></img-upload>
    </el-form-item>
    <el-form-item label="名称描述">
      <el-input v-model="form.nameDesc" style="width:400px;" type="textarea"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="ensure">确 认</el-button>
    <el-button @click="cancel">关 闭</el-button>
  </div>
  </el-dialog>
</template>

<script>
import ImgUpload from '../../../components/imgUpload.vue'
import {mapState} from 'vuex'
export default {
  data () {
    var checkImg = (rule,value,callback)=>{
      if (!value) {
        return callback(new Error('请上传图片'));
      }else{
        callback();
      };
    };
    return {
      caseOptions:[],
      caseList:[],
      loading: false,
      dialogVisible:false,
      form:{
        id:'',
        name:'',
        type:'1',
        fixedJumpType:'0',
        jumpUrl:'',
        imgUrl:'',
        beginTime:'',
        endTime:'',
        sort:0,
        nameDesc:''
      },
      rules:{
        name:[
          {required:true,message:'请输入标题',trigger:'blur'},
          {min:1,max:30,message:'公告标题不超过30个字',trigger:'blur'}
        ],
        beginTime:[ 
          {type:'string',required:true,message:'请选择日期',trigger:'change'}
        ],
        endTime:[
          {type:'string',required:true,message:'请选择日期',trigger:'change'}
        ],
        imgUrl:[
          {validator:checkImg,trigger:'change'}
        ]

      },
      pickerOptions:{
        disabledDate(time){
          return time.getTime() < Date.now();
        },
      }
    };
  },
  props:['operType'],
  components: {
    ImgUpload
  },
  computed: {
    ...mapState({
      fixedJumpTypeOptions: state=>state.dictionary.fixedJumpTypeOptions
    })
  },
  methods: {
    // remoteMethod(query) {
    //   if(query !==''){
    //     this.loading = true;
    //     setTimeout(() => {
    //         this.loading = false;
    //         this.caseOptions = this.caseList.filter(item => {
    //           return item.label && item.label.toLowerCase()
    //             .indexOf(query.toLowerCase()) > -1;
    //         });
    //     }, 200);
    //   }else{
    //     this.caseOptions=[];
    //   }
    // },
    // getBiddingList(){
    //   caseManageList({page:1,perPage:100000,businessType:0}).then(res=>{
    //     let data =res.data;
    //     if(data.code ==='000'){
    //       let list = data.content.list;
    //       for(let i=0;i<list.length;i++){
    //         this.caseList.push({
    //           label:list[i].title,
    //           value:String(list[i].caseId)
    //         })
    //       }

    //     }else{
    //       console.log(data.desc)
    //     }
    //   })
    // },
    updateImg(imgUrl){
      if(this.form.imgUrl){
        this.$refs.addBannerForm.clearValidate('imgUrl');
      }
    },
    ensure(){
      this.$refs.addBannerForm.validate((valid)=>{
        if(valid){
          if(this.form.fixedJumpType !=='1' && this.form.fixedJumpType !=='2'){
            this.form.jumpUrl = '';
          }
          this.$emit('ensure',this.form);
          this.dialogVisible = false;
        }else{
          console.log('error submit!!');
          return false;
        }
      })
      
    },
    cancel(){
      this.$refs.addBannerForm.resetFields();
      this.form.nameDesc = '';
      this.form.sort = 0;
      this.form.fixedJumpType='0';
      this.form.id='';
      this.form.jumpUrl="";
      this.dialogVisible = false;
    }
  },
  mounted(){
    // this.getBiddingList();
  }
}

</script>
<style lang='scss' scoped>
</style>