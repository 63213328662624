import instance from './axios'
import axios from 'axios'

//获取七牛token信息接口
export const get_qiniu_token = (para) => {
  return axios.get('/rest-admin/open/assistant/get_qiniu_token', {params:para}, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
}
export const toQiniu=(para)=>{
  return axios.post('https://upload.qiniup.com/',para,{
    headers:{
      'Content-Type': 'application/json',
    }
  })
}

