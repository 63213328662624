<template>
  <div style='display: inline-block;margin-right: 25px;'>
    <div class="file-box" @click='checkidCardFrontImg'>
      <input type="file" accept="image/*" style='display: none;' :id='id'
             @change='changeidCardBackImg(id)'>
      <img :src="img" alt="" v-if='img' class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </div>
    <div class='tip'>
      {{tip}}
    </div>
  </div>
</template>

<script>
  import {get_qiniu_token, toQiniu} from '../api/common'
  
  export default {
    name: "imageUpdate",
    props: ['img','tip','disabled'],
    data() {
      return {
        id:"idCardFrontImg"+Math.random()
      }
    },
    methods: {
      checkidCardFrontImg() {
        if(this.disabled){
          return false
        }

        let idCardFrontImg = document.getElementById(this.id);
        idCardFrontImg.click()
      },
      changeidCardBackImg(dom) {
        let idCardBackImg = document.getElementById(dom);
        let img = idCardBackImg.files[0]
        let formdata = new FormData()
        if(img.size>5*1024*1024){
          return this.$Notice.error({title:'图片大小不能大于5MB'})
        }
    
        formdata.append('file', img)
        get_qiniu_token().then(res => {
          let data = res.data
          if (data.code === '000') {
            this.upToken = data.content.upToken
            this.domain = data.content.domain
            formdata.append('token', this.upToken)
            this.updataQiniu(formdata, dom)
          }
        }).catch(err => {
          console.log(err);
        })
      },
      updataQiniu(para, dom) {
        toQiniu(para).then(res => {
          let data = res.data
          let img = 'https://' + this.domain + '/' + data.key;
          this.$emit('update:img', img)
          this.$emit('updateImg', img)
          console.log(this.img);
          
        }).catch(err => {
          console.log(err);
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .file-box {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    display: inline-block;
    width: 128px;
    height: 128px;
    position: relative;
    &:hover{
      border-color: #409EFF;
    }
    > img {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
      max-width: 108px;
      max-height: 108px;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
  }
  .tip{
    margin-top: 10px;
    color: #999999;
  }
</style>
